<template>
  <section id="profile">
    <div class="col1">
      <h3>Profile</h3>

      <transition name="fade">
        <p v-if="showSuccessProfile" class="success">profile updated</p>
      </transition>

      <form @submit.prevent>
        <label for="name">username (visible to other users)</label>
        <input v-model.trim="name" type="text" :placeholder="userProfile.name" id="name" />

        <label for="description">description (optional)</label>
        <input v-model.trim="description" type="text" :placeholder="userProfile.description" id="description" />

        <button @click="updateProfile()" class="button">Update Profile</button>
      </form>
    
      <h3>Preferences</h3>

      <transition name="fade">
        <p v-if="showSuccessPreferences" class="success">preferences updated</p>
      </transition>

      <!-- TODO: need to hook these up to the current userProfile attributes -->

      <form @submit.prevent>
        <p>Decide who can: </p>
        <p>View your profile and track record</p>
        <select v-model="userProfile.visible">
          <option v-for="option in options" v-bind:key="option.text" v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>

        <p>Follow you and see your live bids</p>
        <select v-model="userProfile.followable">
          <option v-for="option in options" v-bind:key="option.text" v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>

        <p>Ask to be your friend</p>
        <select v-model="userProfile.friendable">
          <option v-for="option in options" v-bind:key="option.text" v-bind:value="option.value">
            {{ option.text }}
          </option>
        </select>

        <br>

        <button @click="updatePreferences()" class="button">Update preferences</button>
      </form>

    </div>

  </section>
</template>

<script>

import { mapState } from 'vuex'

export default {
  data() {
    return {
      name: '',
      description: '',
      showSuccessProfile: false,
      showSuccessPreferences: false,
      visible: '',
      followable: '',
      friendable: '',
      options: [
        { text: 'Public - anyone', value: 'public'},
        { text: 'Private - only friends', value: 'private'},
        { text: 'Hidden - no one', value: 'hidden'}
      ]
    }
  },
  computed: {
    ...mapState(['userProfile'])
  },
  methods: {
    updateProfile() {
      this.$store.dispatch('updateProfile', {
        name: this.name !== '' ? this.name : this.userProfile.name,
        description: this.description !== '' ? this.description : this.userProfile.description
      })

      this.name = ''
      this.description = ''

      this.showSuccessProfile = true

      setTimeout(() => {
        this.showSuccessProfile = false
      }, 2000)
    },

    updatePreferences() {
      this.$store.dispatch('updatePreferences', {
        // name: this.name !== '' ? this.name : this.userProfile.name,
        visible: this.visible !== '' ? this.visible : this.userProfile.visible,
        followable: this.followable !== '' ? this.followable : this.userProfile.followable,
        friendable: this.friendable !== '' ? this.friendable : this.userProfile.friendable,
      })

      this.visible = ''
      this.followable = ''
      this.friendable = ''

      this.showSuccessPreferences = true

      setTimeout(() => {
        this.showSuccessPreferences = false
      }, 2000)
    }
  }
}
</script>
